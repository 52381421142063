@import '../utilities/variables';

.section-separator {
    width: 209px;
    border: 2px solid;

  }
  
  .section-separator.orange {
    border-color: $color-orange;
  }
  
  .section-separator.blue {
    border-color: $color-blue;
  }

  .section-separator .selago {
    border-color: $color-selago;
  }

