@import '../utilities/variables';

#our-values {
  color: white;
  background-color: $color-primary;
  width: 100%;
  min-height: 500px;

  .our-values-container {
    margin: 0 auto;
    max-width: 1210px;
    padding: 90px 30px;

    .our-values-content {
      
    }

    .our-values-header {
      display: flex;
      flex-direction: column;
      align-items: center;

      .header-title {
        font-weight: 800;
        font-size: 36px;
        line-height: 54px;
        padding-bottom: 32px;

        @media screen and (max-width: 1023px) {
          font-size: calc(20px + 3vw);
        }
      }
    }

    .our-values-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 114px;
      gap: 40px 125px;

        .card {
            max-width: 320px;
            text-align: center;

            img {
              width: 65px;
            }

            .card-title {
                padding-top: 51px;
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
            }

            .card-description {
                padding-top: 40px;
                font-size: 16px;
                line-height: 24px;
                text-align: justify;
            }

            &:nth-of-type(1) {
                .card-description {
                    letter-spacing: 0.03em; 
                }
            }
        }
    }
  }
}
