#tick {
    visibility: hidden;
    transition: all 1s;
  }
  
  #circle {
    visibility: hidden;

    stroke-width: 6;
    transform-origin: 50px 50px 0;
    transition: all 1s;
  
    stroke-dasharray: 500;
  }
  
  .progress {
    #tick {
      opacity: 0;
    }
  
    #circle {
      visibility: visible;
      stroke: #4c4c4c;
      stroke-dasharray: 314;
      stroke-dashoffset: 1000;
      animation: spin 3s linear infinite;
    }
  }
  
  .ready {
    #tick {
      visibility: visible;
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: draw 8s ease-out forwards;
      stroke: #63bc01;
      stroke-width: 6;
      opacity: 1;
    }
  
    #circle {
      visibility: visible;
      stroke-dashoffset: 66;
      stroke: #63bc01;
      stroke-dasharray: 500;
    }
  }
  
  @keyframes spin {
    0% { 
      transform: rotate(0deg);
      stroke-dashoffset: 66;
    } 
    50% {
      transform: rotate(540deg);
      stroke-dashoffset: 314;
    } 
    100% {
      transform: rotate(1080deg);
      stroke-dashoffset: 66;
    }
  }
  
  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
  
  #check {
    width: 300px;
    height: 300px;
  }
