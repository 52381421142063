@import '../utilities/variables';


body {
    font-family: 'Poppins', sans-serif;
}

// Headings
h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
}

input, textarea {
    font-family: 'Poppins', sans-serif;
}