@import '../utilities/variables';

footer {
    background-color: $color-primary;
    color: $color-white;
    
    .footer-container {
        max-width: 1220px;
        margin: 0 auto;
        padding-top: 72px;
        padding: 72px 30px;

        .footer-section-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding-bottom: 60px;
            gap: 50px 150px;

            h1 {
                font-size: 30px;
                font-weight: 400;
                padding-bottom: 30px;
            }

            .logo-section {
                max-width: 300px;
                flex: 1 300px;
                line-height: 22px;
                letter-spacing: 0.2px;
                word-spacing: 2px;
                font-style: italic;

                .logo-wrapper {
                    img {
                        width: 100px;
                    }
                }

                .company-description-wrapper {
                    padding-top: 30px;

                    .company-executive {
                        padding-top: 10px;
                    }
                }
            }

            .contact-section {
                flex: 1 300px;
                max-width: 300px;
                .contact-info {
                   

                    .our-contacts-container {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        
                        h2 {
                            font-weight: 400;
                            display: flex;
                            align-items: center;
                        }

                        img {
                            margin-right: 20px;
                            width: 21px;
                        }
                    }
                }
            }

            .social-media-section {
                flex: 1 300px;
                max-width: 300px;
            }
        }

        .section-separator {
            width: 100%;
            border: 1px solid;
        }

        .copyright-section {
            padding: 50px 0;
            h3 {
                font-weight: 300;
            }
        }
    }
}