// For Inter font
@font-face {
    font-family: 'Inter';
    src: url('../fonts/inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
    font-weight: 100 900;
  }
  
  // For Poppins font
  @font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
    font-weight: 800;
  }
  