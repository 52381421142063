@import '../utilities/variables';

#about-us {
  padding-top: 82px;
  color: $color-white;

  .section-container {

    .content-wrapper {

      .content-block-wrapper {
        margin: 0 auto;
        max-width: 1210px;
        padding: 0 30px;
        
      }

      .full-width-background {
        width: 100%;

        &:first-child {
          // styles for the first full-width background
          background-color: $color-primary;

          @media screen and (max-width: 1023px) {
            padding-top: 30px;
          }

          .content-block-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;


            .content-block {
              max-width: 537px;
              flex: 1;

              @media screen and (max-width: 1023px) {
                display: flex;
                flex-direction: column;
                align-items: center;
              }
              

              .headline {
                font-size: 48px;
                line-height: 72px;
                max-width: 676px;
                font-weight: 800;
                padding-bottom: 40px;

                  @media screen and (max-width: 1023px) {
                    font-size: calc(20px + 2vw);
                  }
              }

              .subhead {
                padding-top: 56px;
                font-weight: 400;
                font-size: 24px;
                line-height: 36px;
                padding-bottom: 66px;
              }
            }

            .illustration-block {
              flex: 1;
              align-self: flex-end;

              @media screen and (max-width: 1023px) {

                display: none;

              }

              img {
          
                z-index: 2;
                width: 100%;
                height: auto;
                transform: translateY(3px);
              }


            //   &::before {
            //     content: "";
            //     position: absolute;
            //     top: 0;
            //     right: -30px;
            //     width: 397px;
            //     height: 407px;
            //     background: #FE7926;
            // }
            }

            .cta-button {
                padding: 10px 42px;
                color: white;
                border-color: white;
                font-size: 24px;
                margin-bottom: 50px;
            }
            .mobile-image {
              display: none;
              @media screen and (max-width: 1023px) {
                max-width: 100%;
                display: block;
                max-height: calc(267px + 5vw);
                margin: 0 auto;
              }

            }
          }
        }

        &:last-child {
          // Your styles for the second full-width background
          background-color: $color-blue;

          .content-block-wrapper {
            display: flex;
            height: 100%;
            flex-wrap: wrap;
            justify-content: space-between;

            @media screen and (max-width: 1023px) {

              max-width: 600px;

            }
           

            .content-block {
            
              align-self: center;
              max-width: 537px;
              border-left: 3px solid;
              padding-left: 54px;
              max-width: 50%;

              @media screen and (max-width: 1023px) {

                max-width: 100%;
                border-left: none;
                padding-left: 0;
                padding: 40px 0;

              }

              h2 {
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                padding-bottom: 23px;
              }

              h3 {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: justify;
                letter-spacing: -0.02em;
              }
            }

            .illustration-block {
              position: relative;
              max-width: 50%;
              flex: 1;
              max-height: 415px;

              @media screen and (max-width: 1023px) {

                display: none;

              }
              
            }

            img {
              height: calc(100% + 30px);
              transform: translateY(-30px);
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1023px) {

  #about-us {

      .full-width-background * {
        text-align: center;

      }

    
  }
}