@import '../utilities/variables';

#packages {
  color: $color-white;
  background-color: $color-primary;
  width: 100%;
  min-height: 500px;

  .packages-container {


    .packages-content {
        margin: 0 auto;
        padding: 90px 30px;
        max-width: 1280px;
    }

    .packages-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 86px;

      .header-title {
        color: $color-white;
        font-weight: 800;
        font-size: 36px;
        line-height: 54px;
        padding-bottom: 32px;

        @media screen and (max-width: 1023px) {
          font-size: calc(20px + 3vw);
        }
      }
    }

    .packages-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 100px;

        .card {
            width: 350px;
            background-color: $color-dark;
            text-align: center;
            gap: 50px;
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4); 
            transition: transform 0.3s ease-in-out;

            &:hover {
                transform: scale(1.05);
               
            }

            .card-title {
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                padding: 44px;
            }

            .card-description {
                font-size: 14px;
                line-height: 21px;
                letter-spacing: -0.03em;
                padding: 0 20px 30px;
            }

            img {
                transform: translateY(-40px);
                max-width: 150px;
            }
        }
    }
  }
}
