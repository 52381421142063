@import '../utilities/variables';


button {
    border: 2px solid;
    border-radius: 47px;
    background: transparent;
    font-family: 'Inter';
    cursor: pointer;
}

.button {
    border: 2px solid;
    border-radius: 47px;
    background: transparent;
    font-family: 'Inter';
    cursor: pointer;
}