@import '../utilities/variables';

#contact {
    width: 100%;
    background: $color-gradient;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    min-height: 691px;
    color: $color-white;
    overflow: hidden;

    .contact-container {
        margin: 0 auto;
        max-width: 1210px;
        padding: 20px 30px;

        .contact-header {
            .header-title {
                font-weight: 800;
                font-size: 64px;

                @media screen and (max-width: 1023px) {
                    font-size: calc(20px + 3vw);
                }

                @media screen and (max-width: 947px) {
                    text-align: center;
                }
            }

        }



        .contact-content-container {
            display: flex;
            flex-wrap: wrap;
            gap: 50px;
            position: relative;

            @media screen and (max-width: 947px) {
                align-content: center;
                flex-direction: column;
                padding-bottom: 0px;
            }



            .our-contacts-container {
                padding-top: 34px;
                flex: 1 auto;

                img {
                    padding-right: 20px;
                    width: 25px;
                }

                h2 {
                    font-size: 24px;
                    line-height: 36px;
                    letter-spacing: -0.01em;
                    font-weight: 400;

                    @media screen and (max-width: 1023px) {
                        font-size: calc(16px + 1vw);
                        display: flex;
                        align-items: center;
                    }
                }

                .mobile-hide {
                    display: none;
                }
            }



            .contact-form-container {
                flex: 2 401px;
                //box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
                padding: 20px;
                max-width: 500px;
                position: relative;

                &.sending {
                    form {
                        filter: blur(5px);
                    }
                }


                &.sent {
                    p {
                        padding-top: 30px;
                        line-height: 30px;
                        text-align: center;
                        font-size: 18px;
                    }
                }


                @media screen and (max-width: 947px) {
                    flex: auto;
                    min-height: auto !important;
                }

                form {
                    display: flex;
                    flex-direction: column;
                    color: white;
                    transition: filter 0.3s ease-in-out;


                    input {
                        background-color: transparent;
                        color: $color-white;
                        height: 49px;
                        border: 1px solid rgba(255, 255, 255, 0.5);
                        border-radius: 9px;
                        text-indent: 10px;
                        margin-bottom: 14px;
                    }

                    textarea {
                        background-color: transparent;
                        color: $color-white;
                        height: 133px;
                        border: 1px solid rgba(255, 255, 255, 0.5);
                        border-radius: 9px;
                        text-indent: 10px;
                        padding-top: 10px;
                        margin-bottom: 14px;
                        resize: none;
                    }

                    input::placeholder,
                    textarea::placeholder {
                        color: white;
                    }

                    label {
                        padding-bottom: 16px;
                        font-size: 16px;
                    }

                    .button {
                        width: 160px;
                        text-indent: 0;
                        border-radius: 47px;
                        align-self: flex-end;
                        background-color: transparent;
                        color: #fff;
                        height: 49px;
                        border: 1px solid rgba(255, 255, 255, 0.5);
                        border-radius: 9px;
                        margin-bottom: 38px;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media screen and (max-width: 947px) {
                            align-self: flex-start;
                            height: 40px;
                        }
                    }

                    .send-button {
                        margin-top: 1.5em;
                    }

                    input.error,
                    textarea.error {
                        border: 1px solid red;
                        outline: none;
                    }

                    input.error:focus,
                    textarea.error:focus {
                        border: 1px solid #e57373;
                        outline: none;
                    }

                    input.error:active,
                    textarea.error:active {
                        border: 1px solid #e57373;
                        outline: none;
                    }

                    input.error:focus-visible,
                    textarea.error:focus-visible {
                        border: 1px solid #e57373;
                        outline: none;
                    }

                    .error-message {
                        padding-bottom: 30px;
                        color: red;
                    }
                }

                .thank-you-container {
                    display: flex;
                    flex-direction: column;
                    height: 100%;

                    .thank-you {
                        font-style: italic;
                        padding-bottom: 50px;
                        padding-top: 50px;
                    }

                    .button-wrapper {
                        display: flex;
                        height: 100%;
                        justify-content: center;
                        padding-top: 50px;

                        .button {
                            width: 160px;
                            text-indent: 0;
                            border-radius: 47px;
                            align-self: flex-end;
                            background-color: transparent;
                            color: #fff;
                            height: 49px;
                            border: 1px solid rgba(255, 255, 255, 0.5);
                            border-radius: 9px;
                            margin-bottom: 38px;
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    svg {
                        position: static;
                        padding-top: 50px;
                    }

                }

                svg {
                    position: absolute;
                    inset: 0;
                    margin: auto;
                    width: 25%;
                    height: auto;
                }

                .error-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 600px; 
                    background-color: #ffdddd; 
                    border: 1px solid #ff0000; 
                    color: #d8000c; 
                    padding: 20px;
                    border-radius: 5px;
                    text-align: center;
                  
                    .error-message {
                      font-size: 20px;
                      font-weight: bold;
                      margin-bottom: 15px; 
                    }
                  
                    .contact-suggestion {
                      font-size: 18px;
                      margin-bottom: 20px; 
                    }
                  
                    .contact-info {
                      font-size: 16px;
                      margin-bottom: 30px;
                  
                      p {
                        margin: 10px 0; 
                  
                        a {
                          color: #0052cc; 
                          text-decoration: none;
                  
                          &:hover {
                            text-decoration: underline;
                          }
                        }
                  
                        .icon {
                          margin-right: 5px;
                        }                                       
                      }
                    }
                  
                    .button-wrapper {
                        display: flex;
                        height: 100%;
                        justify-content: center;
                        padding-top: 50px;

                        .button {
                            width: 160px;
                            text-indent: 0;
                            border-radius: 47px;
                            align-self: flex-end;
                            background: linear-gradient(180deg, #171717 7%, #7B61FF 137.61%);
                            color: #fff;
                            height: 49px;
                            border: 1px solid rgba(255, 255, 255, 0.5);
                            border-radius: 9px;
                            margin-bottom: 38px;
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                  }
                  
            }



            .contact-image-container {
                position: absolute;
                bottom: 0;
                left: -6vw;
                max-width: 50%;

                img {
                    width: 100%;
                    object-position: -90px;
                }

                @media screen and (max-width: 947px) {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {}