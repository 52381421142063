@import '../utilities/variables';

.carousel {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
  
    .carousel-images {
      display: flex;
      transition: transform 0.5s ease-in-out;
  
      .carousel-image {
        width: 100%;
        flex: 0 0 100%; 
        height: auto; 
        object-fit: contain; 
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
  
        &.visible {
          opacity: 1;
        }
      }
    }
  
    .carousel-dots {
      position: absolute;
      bottom: 10px;
      display: flex;
      justify-content: center;
      width: 100%;
  
      .carousel-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: white;
        margin: 0 5px;
        cursor: pointer;
  
        &.active {
          background: $color-blue;
        }
      }
    }
  }
  