@import '../utilities/variables';

#services {
  color: $color-primary;
  background-color: $color-primary;
  width: 100%;
  min-height: 500px;

  .services-container {


    .services-content {
        margin: 0 auto;
        padding: 90px 30px;
        max-width: 1210px;
    }

    .services-header {
      display: flex;
      flex-direction: column;
      align-items: center;

      

      .header-title {
        color: $color-white;
        font-weight: 800;
        font-size: 36px;
        line-height: 54px;
        padding-bottom: 32px;

        @media screen and (max-width: 1023px) {
            font-size: calc(20px + 2vw);
          }
      

      }
    }

    .services-cards {
        display: flex;
        flex-wrap: wrap;
        padding-top: 96px;
        gap: 40px 87px;
        justify-content: center;
        padding: 96px 20px 0 20px;

        .card {
            max-width: 320px;
            text-align: center;
            background-color: $color-selago;

            min-width: 332px;
            min-height: 447px; 
            
            @media screen and (max-width: 350px) {
                min-width: 100%;
                max-width: 100%;
            }

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
            transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out; /* Add transition for transform property */
        

            &:hover {
                background-color: $color-blue;
                color: $color-white;
                box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.6);
                transform: scale(1.05); 
              

                img {
                    filter: invert(100%)
                }

                .card-button {
                    color: $color-white;
                }

            }

            img {
                width: 65px;
                padding-top: 31px;
            }

            .card-title {
    
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
            }

            .card-description {
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                letter-spacing: -0.015em;
                max-width: 252px;
            }

            .card-button {
                margin-bottom: 31px;
                font-family: "Poppins";
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                padding: 7px 25px;
                cursor: pointer;

            }

            &:nth-of-type(1) {
                .card-description {
                    letter-spacing: -0.03em;
                }
            }

            &:nth-of-type(2) {
                .card-description {
                    letter-spacing: -0.02em;
                }
            }

            &:nth-of-type(3) {
                .card-description {
                    
                }
            }

            &:nth-of-type(4) {
                .card-description {
                    letter-spacing: -0.02em;
                    max-width: 272px;
                }
            }

            &:nth-of-type(5) {
                .card-description {
                    letter-spacing: -0.03em;
                    max-width: 270px;
                }
            }

            &:nth-of-type(6) {
                .card-description {
                    letter-spacing: -0.015em;
                    max-width: 310px;
                }
            }

        }
    }

    .data-display-outer {
        width: 100%;
        background-color: $color-orange;
        min-height: 248px;
        color: $color-white;

        .data-display-container {
            max-width: 1210px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            min-height: 248px;
            padding: 0 30px;

            @media screen and (max-width: 768px) {
                flex-direction: column;
                align-content: center;
                align-items: normal;
                padding: 60px 30px 0px 30px;
            }

        
            .data-display-item {
                padding: 0 20px;

                .data-display-number {
                    padding-bottom: 32px;
                    font-weight: 800;
                    font-size: 36px;

                    @media screen and (max-width: 768px) {
                        padding-bottom: 24px;
                    }
                }

                .data-display-title {
                    font-size: 24px;

                    @media screen and (max-width: 768px) {
                        padding-bottom: 60px;
                    }
                }
            }
        }
    }

  }
}
