//Colors
$color-primary: #171717;
$color-secondary: #FEF1E9;
$color-orange: #FE7926;
$color-blue:    #6E75FF;
$color-selago: #E5E1FC;
$color-gradient: linear-gradient(180deg, #171717 7%, #7B61FF 137.61%);
$color-light: #f8f9fa;
$color-dark: #232323;
$color-white: #fff;
$color-black: #000;
$color-error: #F04438;
$color-primary-blue: #1C6893;
$color-gray-dark: #101828;
$color-gray-light: #475467;

$colors: (
    'primary': $color-primary,
    'secondary': $color-secondary,
    'orange': $color-orange,
    'blue': $color-blue,
    'selago': $color-selago,
    'gradient': $color-gradient,
    'light': $color-light,
    'dark': $color-dark,
    'white': $color-white,
    'black': $color-black,
    'error': $color-error,
    'primary-blue': $color-primary-blue,
    'gray-dark': $color-gray-dark,
    'gray-light': $color-gray-light
);
