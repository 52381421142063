@import '../utilities/variables';


.navbar {
    position: fixed;
    width: 100vw;
    top: 0;
    height: 82px;
    background-color: $color-primary;
    color: $color-white;
    z-index: 99;

   
      
.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: auto;
  max-width: 1920px;
  padding: 0 60px 0 60px;
  align-items: center;
  position: relative;
  margin: 0 auto;


  .logo-container {
    img {
      width: 100px;
    }
  }

  .menu-item-container {

    overflow: hidden;
    transition: 0.5s ease-in-out;
    background-color: $color-primary;
    z-index: 99;


    .menu {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 40px;
      gap: 2em;

      a {
        position: relative;
        
      }

      a:before {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 3px;
        width: 0;
        background-color: $color-blue; 
        transition: width 0.3s ease;
      }

      a.active:before {
        width: 100%; 
      }
    }
  }

  .menu-toggle {
    display: none;
    cursor: pointer;
    background-color: transparent;
    padding: 15px 0;

    .hamburger {
      width: 30px;
      height: 3px;
      background-color: $color-white;
      position: relative;

      &::before, &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $color-white;
      }

      &::before {
        transform: translateY(-10px);
      }

      &::after {
        transform: translateY(10px);
      }
    }
  }
}
}

@media screen and (max-width: 1023px) {
    .navbar {

      .overlay {
        visibility: hidden;
        position: fixed;
        top: 82px;
        width: 100%;
        height: 100%;
        background-color: rgba(23, 23, 23, 0.7);
        z-index: 98;

        &.open {
            visibility: visible;
        }
      }
        .navbar-container {
            padding: 0 30px 0 30px;

            .menu-item-container {
                right: 0;
                position: fixed;
                height: 100vh;
                top: 82px;
                width: 260px;
                background: linear-gradient(135deg, #171717, #1c1c1c 50%, #141414 50%);
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
                transform: translateX(100%);
                transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;

                &.open {
                    transform: translateX(0);
                    visibility: visible;
                    position: fixed;
                    height: 100vh;
                    top: 82px;
                    width: 260px;
                  
                }

                &:hover {
                    box-shadow: 0 10px 40px rgba(0, 0, 0, 1);
                }

                & .menu {
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  padding: 20px;
                }

                &.open .menu {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 20px;
                }
            }

    .menu-toggle {
        display: block;
      }
   
      }
    }
}
