@import '../utilities/variables';

#projects {
  color: $color-primary;
  background-color: $color-primary;
  min-height: 500px;

  .projects-container {

    img {
      width: 100%;
    }

    .mobile {
      display: none;

      @media (max-width: 768px) {
        display: block;
      }
    }

    .desktop {
      

      @media (max-width: 768px) {
        display: none;
      }
    }

    .swiper {
      .swiper-pagination-bullets {
       bottom: 20px; 
      }

      .swiper-button-prev:after, .swiper-button-next:after {
        color: $color-white;
      }

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background-color: $color-white;
        opacity: 1;
        
      }
      .swiper-pagination-bullet-active {
        background-color: $color-blue;
      }
    }


    .projects-content {
        margin: 0 auto;
        padding: 90px 30px;
        max-width: 1210px;
    }

    .projects-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 86px;

      .header-title {
        color: $color-white;
        font-weight: 800;
        font-size: 36px;
        line-height: 54px;
        padding-bottom: 32px;

        @media screen and (max-width: 1023px) {
             font-size: calc(20px + 3vw);
        }
        
      }
    }
  }
}
